.homepage-reshaping {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        text-align: left;
        margin: 0;
        max-width: 950px;
        margin: 0 10px;
    }
    img {
        margin-top: 50px;
        margin-right: 5vw;
    }
}

.homepage-solutions {
    @include flex-center-all;
    flex-direction: column;
    text-align: center;
    margin: 0;
    section, a {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 600px;
        padding: 15px;
        background-color: $sprout-gray-2;
        text-decoration: none;
        &:nth-child(odd){
            background-color:white;
        }
        img {
            max-width: 100px;
        }
    }

    h3 {
        margin: 8px 0;
        color: $sprout-font-gray;
    }

    @include breakpoint('md') {
        flex-wrap: wrap;
        flex-direction: row;
        margin: 0 35px 50px 35px;
        border: 1px solid $sprout-gray-2;
        section, a {
            justify-content: space-around;
            width: 50%;
            height: 18vw;
            max-height: 252px;
            padding: 32px 40px;
            &:nth-child(3){
                background-color: $sprout-gray-2;
            }
            &:nth-child(4){
                background-color:white;
            }
        }
        img {
            width: 7.16vw;
            max-height: 100px;
        }
    }

    @include breakpoint('lg') {
        section, a {
            width: 33.33%;
            &:nth-child(odd){
                background-color:white;
            }
            &:nth-child(even){
                background-color: $sprout-gray-2;
            }
        }
    }
}

.homepage-formats {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: $sprout-blue;
    padding: 34px;
    &-tile {
        color: white;
        margin: 5px;
        width: 100%;
        max-width: 300px;
        height: 200px;
        text-align: left;
        padding: 0px 15px 20px 0px;
        border: 1px solid $sprout-blue;
        background-size: cover;
        background-repeat: no-repeat;
        transition: .5s;
        &:hover {
            border: 1px solid white;
        }
        &-content {
            padding: 20px 5px 5px 15px;
            width: 80%;
            transition: .5s;
            height: 70%;
            &-hidden {
                width: 0px;
                height: 0px;
                margin: -1px;
                overflow: hidden;
                clip: rect(0 0 0 0);
                font-size: 0px !important;
            }
        }
        &-digital { 
            background-image: url('../../Assets/images/Stock/Stock_Hero_Sub_2.jpg');
            div { background-color: rgb(172, 191, 206, .75); }
        }
        &-live-online {
            background-image: url('../../Assets/images/Public-Website/semi-circle-sections/coachmasters.jpg');
            div { background-color: rgb(122, 110, 102, .75); }
        }
        &-traditional {
            background-image: url('../../Assets/images/Stock/Stock_Hero_Sub_1.jpg');
            div { background-color: rgb(142, 171, 165, .75); }
        }
    }
    @include breakpoint('md'){
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 34px;
        &-tile {
            max-width: unset;
            width: 33%;
            height: 20vw;
            max-height: 300px;
        }
    }
}
