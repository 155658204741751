.coachingLibrary-link {
    text-decoration: none;
    color: white;
    @include breakpoint('md') {
        color: $sprout-blue;
    }
}

.coachingLibrary-link-active {
    text-decoration: none;
    color: $sprout-blue;
    @include breakpoint('md') {
        color: black;
        font-weight: 700;
    }
}

.coachingLibrary {
    // Landing
    &-section-header {
        text-align: left;
        color: $sprout-font-gray;
        padding: 10px 20px;
        h2 {
            margin: 0;
            font-weight: $fontweight-bold;
        }
    }
    &-card-wrap {
        @include flex-center-all;
        flex-wrap: wrap;
        width: 100%;
    }
    // FIlters
    &-filterbar {
        @include flex-between-center;
        padding: 10px;
        @include breakpoint('md') {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
        }
        &-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
            height: 100vh;
            width: 100vw;
            background:rgba(0,0,0,0.9);
            backdrop-filter: blur(3px);
        }
        &-hamburger {
            @include flex-center-all;
            color: $sprout-dark-gray-4;
            border: 1px solid $sprout-dark-gray-4;
            background-color: white;
            padding: 5px 6px;
            &:hover {
                color: black;
                border: 1px solid black;
            }
        }
        &-filters {
            position: absolute;
            top: 54px;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            z-index: 5;
            color: white;
            @include breakpoint('md') {
                top: 0;
                position: relative;
                color: $sprout-blue;
                flex-direction: row;
                z-index: 2;
            }
            &-item {
                @include flex-between-center;
                padding: 20px 10px 10px 10px;
                p {
                    margin: 0;
                }
            
                &-dropdown {
                    list-style: none;
                    text-align: left;
                    margin: 0;
                    padding: 0 30px 0 20px;
                    li {
                        span {
                            @include flex-between-center;
                            text-decoration: none;
                            padding: 10px 5px;
                            p {
                                margin: 0;
                            }
                        }
                    }
                }
                @include breakpoint('md') {
                    position: relative;
                    justify-content: flex-start;
                    border-bottom: 2px solid white;
                    margin-right: 10px;
                    &:hover {
                        border-bottom: 2px solid $sprout-blue;
                    }
                    &-dropdown {
                        width: 250px;
                        position: absolute;
                        top: 57px;
                        background-color: white;
                        padding: 0 8px 10px 8px;
                        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
                    }
                }
            }

            &-header {
                @include flex-center-all;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                z-index: 6;
                background-color: $sprout-blue;
                color: white;
                height: 54px;
                padding: 10px 2px;
                button {
                    position: fixed;
                    left: 10px;
                    right: auto;
                    border: none;
                    background: none;
                    color: white;
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
                p {
                    margin: 0;
                }
            }
        }
        &-search {
            @include flex-center-all;
            color: $sprout-dark-gray-4;
            border: 1px solid $sprout-dark-gray-4;
            background-color: white;
            padding: 5px 6px;
            &:hover {
                color: black;
                border: 1px solid black;
            }
            &-open {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                z-index: 7;
                background-color: $sprout-blue;
                color: white;
                padding: 10px 2px;
                aside {
                    @include flex-between-center;
                    width: 100%;
                    button {
                        border: none;
                        background: none;
                        color: white;
                        svg {
                            width: 25px;
                            height: 25px;
                        }
                    }
                    input {
                        padding: 5px 10px;
                        width: calc(100% - 80px);
                    }
                }
                &-list {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 70px;
                    list-style: none;
                    background-color: transparent;
                    margin: 0;
                    padding: 0;
                    z-index: 6;
                    li {
                        border-bottom: 1px solid white;
                        text-align: left;
                        a {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            color: white;
                            text-decoration: none;
                            padding: 5px 20px;
                            img {
                                width: 100px;
                                height: 50px;
                                min-width: 100px;
                                min-height: 50px;
                                object-fit: cover;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    // Post
    &-post {
        @include flex-center-all;
        flex-direction: column;
        background-color: $sprout-gray;
        &-paths {
            text-align: left;
            margin: 10px;
            p {
                text-align: left;
                margin: 0;
            }
            a {
                text-decoration: none;
                color: $sprout-blue;
                &:hover {
                    color: black;
                    text-decoration: underline;
                }
            }
            small {
                margin: 0 8px;
            }
        }
        &-content { 
            padding: 10px;
            border: 1px solid $sprout-dark-gray;
            max-width: 900px;
            background-color: white;
            @include breakpoint('md') {
                padding: 34px;
                margin-bottom: 50px;
            }
            &-viewer {
                // outline: none;
                // border: none;
                width: 100%;
                height: 500px;
                max-height: 55vh;
                margin: 15px 0 20px 0;
            }
            &-download {
                color: $sprout-blue;
                background-color: $sprout-gray-2;
                text-decoration: none;
                padding: 12px 30px;
                &:hover {
                    color: black;
                    background-color: $sprout-dark-gray;
                }
            }
            &-summary {
                padding: 20px;
                text-align: left;
                line-height: 1.5;
                h2 {
                    margin: 10px 0 20px 0;
                    padding: 15px 0;
                    border-bottom: 1px solid $sprout-dark-gray;
                }
                p {
                    margin: 0 0 5px 0;
                }
            }
        }
    }
}
