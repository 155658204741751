.contact-us-form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 34px 34px;
    min-height: 400px;
    h2 {
        padding-top: 44px;
        padding-bottom: 18px;
        margin: 0;
    }
    h3 {
        width: 100%;
        max-width: 600px;
        background-color: $sprout-gray;
        padding: 30px;
        margin: 30px 30px 0 30px;
    }
    #hubspotContactFormGeneral, #hubspotContactFormServiceSupport {
        width: 100%;
        max-width: 600px;
        margin: 0;
    }
    form {
        width: 100%;
        // margin: 0 30px 30px;
        padding: 0 30px 30px;
        background-color: $sprout-gray;
        text-align: left;
        input, textarea {
            width: 100%;
            margin: 5px 0 15px 0;
        }
        input {
            border: none;
            padding: 6px;
        }
        #id_like_to_talk_about-655fd5ea-398d-4a35-aeb9-cda491c25682 {
            height: 160px;
            border: none;
            padding: 6px;
            width: 100%;
        }
        #issue_description-3d173009-de8b-48a2-89c3-9047744aa986 {
            height: 209px;
            margin: 0 0 9px 0;
            border: none;
            padding: 6px;
        }
        input[type=submit] {
            width: 100%;
            margin-top: 20px;
            padding: 12px;
            background-color: $sprout-salmon;
            border: none;
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 18px;
            cursor: pointer;
        }
    }
    &-toggle {
        &-button {
            border: none;
            margin: 0 2px;
            padding: 12px;
            color: black;
            text-decoration: none;
            text-transform: uppercase;
            width: auto;
            font-size: 18px;
            &:hover {
                background-color: $sprout-salmon;
                color: white;
            }
        }
        .active {
            background-color: $sprout-salmon;
            color: white;
        }
    }
}

.contact-banner {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: left;
    background-image: url('../../../Assets/images/Public-Website/design/background-swoosh-blue.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $sprout-blue;
    &-content {
        padding: 4vw 34px 0 34px;
        padding-bottom: 0;
        width: 100%;
        color: white;
        &-header {
            margin: 0;
            margin-bottom: 20px;
            margin-left: 12px;
        }
        &-subheader {
            margin: 0;
            margin-left: 12px;
        }
        form {
            display: flex;
            // flex-direction: column;
            flex-wrap: wrap;
            margin-top: 15px;
            width: 100%;
            .hs-form-field {
                position: relative;
                width: 50%;
                margin: 10px 0;
                #label-firstname-d8cbab00-a2ae-441e-8da9-ac89b98da001, #label-lastname-d8cbab00-a2ae-441e-8da9-ac89b98da001, #label-email-d8cbab00-a2ae-441e-8da9-ac89b98da001, #label-phone-d8cbab00-a2ae-441e-8da9-ac89b98da001, #label-company-d8cbab00-a2ae-441e-8da9-ac89b98da001, #label-how_can_we_help-d8cbab00-a2ae-441e-8da9-ac89b98da001 {
                    position: absolute;
                    top: -10px;
                    left: 10px;
                    padding: 0 4px 0 4px;
                }

                input, textarea {
                    margin: 12px 0 12px 12px;
                    padding: 10px;
                    border: none;
                    width: calc(100% - 24px);
                    max-height: 42px;
                }
            }
            input[type=submit] {
                align-self: flex-start;
                width: auto;
                min-width: 118px;
                margin-top: 22px;
                margin-left: 12px;
                padding: 12px;
                background-color: $sprout-salmon;
                border: none;
                color: white;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 18px;
                cursor: pointer;
            }
            ul {
                position: absolute;
                top: -10px;
                left: 10px;
                margin: 0;
                padding: 0;
                right: 12px;
                list-style: none;
                text-align: right;
                color: gold;
            }
        }
        &-message {
            font-weight: lighter;
            margin-left: 12px;
            color: white;
        }
    }
}

.contact-form-submit-message {
    font-family: 'Montserrat';
    color: $sprout-salmon;
    margin: 10px 0 0 0;
    font-size: 14px;
}

@media (max-width: $mid-breakpoint) {
    .contact-banner-content {
        padding: 15px;
        form {
            width: 100%;
            .hs-form-field {
                width: 100%;
            }
        }
    }
}

@media (max-width: $small-breakpoint) {
    .contact-us-form-section {
        padding: 0;
        &-toggle-button {
            font-size: 14px;
        }
    }

    .contact-banner-content {
        &-header, &-subheader, button, div {
            margin-left: 0px;
            margin-right: 0px;
        }
        form {
            label {
                font-size: 12px;
            }
        }
    }
}
