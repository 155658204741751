.about-us {
    &-hero {
        background-position: center;
        height: 300px;
        background-image: url('../../Assets/images/Stock/About_Us_Hero.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        text-align: left;

        @include breakpoint('md') {
            height: 70.48vw;
            max-height: 986px;
        }

        h1 {
            padding: 80px 10px 10px 10px;
            background-image: url('../../Assets/images/Public-Website/design/yellow-semi-circle.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-color: rgba(249, 180, 10, .5);
            color: white;
            margin: 0;

            @include breakpoint('md') {
                padding: 100px 34px 50px 34px;
            }
        }
    }

    section {
        @include flex-column;
        align-items: center;
        padding: 20px 10px;

        @include breakpoint('md') {
            padding: 44px 34px;
        }

        h2 {
            text-align: center;
            margin: 5px 0 30px 0;
            padding-left: 5px;
            width: 100%;
            @include breakpoint('md') {
                text-align: left;
            }
        }
    }

    &-description {
        text-align: left;
        width: 100%;
        padding: 20px 10px;
        flex-direction: column;

        @include breakpoint('lg') {
            @include flex-between;
            flex-direction: row;
            padding: 0;
        }
        p,
        li {
            margin: 10px 0;
            padding: 0px 5px;
            @include breakpoint('lg') {
                margin: 0;
            }
        }

        h3,
        p {
            @include breakpoint('md') {
                margin: 18px 0;
            }
        }

        div {
            flex: 1;
            margin: 0;
            padding: 0;

            ul {
                padding: 0 30px;
            }
        }

        div:nth-child(2) {
            background-color: $sprout-dark-gray;
        }

        section {
            padding: 20px 10px;

            h2 {
                text-align: center;
                margin: 0 0 20px 0;
            }
        }
    }

    &-difference {
        text-align: left;
        background-color: $sprout-gray;

        strong {
            text-align: left;
            width: 100%;
            padding-left: 5px;
        }

        p {
            margin: 5px 0 45px 0;
            padding-left: 5px;

            strong {
                color: rgb(68, 68, 68);
            }
        }
    }

    &-leadership {
        &-team {
            align-items: center;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 13px;
            padding: 0;
            width: 100%;

            img {
                width: 250px;
                height: 338.66px;
                max-height: 568px;
                max-width: 420px;
                @include breakpoint('lg') {
                    width: 30vw;
                    height: 40.61vw;
                }
            }

            &>* {
                flex: 0 0 33.3333%;
            }

            &-member {
                margin: 15px;
                position: relative;
                text-align: left;
                @include breakpoint('lg') {
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    margin-bottom: 4.2vw;
                }
                img {
                    width: 250px;
                    height: 338.66px;
                    margin-bottom: 10px;

                    @include breakpoint('md') {
                        width: 30vw;
                        height: 40.61vw;
                        max-height: 568px;
                        max-width: 420px;
                    }
                }
                strong {
                    margin: 21px 0 10px 0;
                }
                p {
                    margin: 0;
                }
                &-linkedIn {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    background-color: $sprout-dark-gray;
                    color: white;
                    text-decoration: none;
                    padding: 2px 5px;

                    &:hover {
                        color: $sprout-blue;
                        background-color: white;
                    }
                }
            }
        }
    }

    &-rds {
        img {
            margin-top: 30px;
            width: 100%;
            max-width: 1206px;
        }
    }
}