.header-semicircle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-image: url('../../Assets/images/Public-Website/design/header-blue-semi-circle.svg');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-color: $sprout-blue;
    text-align: left;
    padding: 50px 10px 30px 10px;
    width: 100%;
    color: white;
    h1 {
        margin: 0;
        padding-top: 20px;
    }

    @include breakpoint('sm'){
        padding: 60px 10px 40px 10px;
    }

    @include breakpoint('md'){
        padding: 70px 34px 50px 34px;
    }
}

.gelcoat-top {
    @include absolute-cover;
    @include size(100%, 25%);
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
    z-index: 1;
}

.gelcoat-left {
    @include absolute-cover;
    @include size(60%, 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
    z-index: 1;
}

.header-hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    aspect-ratio: 32 / 15;
    &-semicircle {
        display: none;
    }
    &-content {
        position: relative;
        padding: 10px 10px;
        padding-top: $header-height-sm;
        color: white;
        text-align: left;
        z-index: 2;
        width: 100%;
        h1 {
            margin: 0;
            max-width: 1000px;
        }
        p {
            margin: 10px 0 20px 0;
        }
    }

    @include breakpoint('sm'){
        &-semicircle {
            @include absolute-cover;
            display: block;
            height: 100%;
            z-index: 0;
        }
        &-content {
            background-color: transparent !important; // override backgrounds below
        }
    }

    @include breakpoint('md'){
        &-content {
            padding: 34px;
            padding-top: $header-height-lg;
            p {
                margin: 28px 0;
                max-width: 50%;
            }
        }
    }

    @include breakpoint('lg'){
        &-content {
            margin-top: 0;
        }
    }

    @include breakpoint('xl'){
        p {
            max-width: 40%;
        }
    }
}

.header-hero-background {
    &-homepage {
        background-image: url('../../Assets/images/Public-Website/hero/homepage-hero.jpg');
        .header-hero {
            &-semicircle { fill: rgba(49, 98, 150, 0.5) }
            &-content { background-color: rgba(49, 98, 150, 0.5) }
        }
    }
    // &-homepage-2 {
    //     background-image: url('../../Assets/images/Public-Website/hero/homepage-hero-2.jpeg');
    //     .header-hero {
    //         &-semicircle { fill: rgba(49, 98, 150, 0.5) }
    //         &-content { background-color: rgba(49, 98, 150, 0.5) }
    //     }
    // }
    &-events {
        background-image: url('../../Assets/images/Public-Website/hero/events-hero.jpeg');
        .header-hero {
            &-semicircle { fill: rgba(49, 98, 150, 0.5) }
            &-content { background-color: rgba(49, 98, 150, 0.5) }
        }
    }
    &-coaching-made-simple {
        background-image: url('../../Assets/images/Public-Website/hero/coaching-made-simple-hero.jpg');
        .header-hero {
            &-semicircle { fill: rgba(51, 148, 151, 0.5) }
            &-content { background-color: rgba(51, 148, 151, 0.5) }
        }
    }
    &-coaching-mastery {
        background-image: url('../../Assets/images/Public-Website/hero/coaching-mastery-hero.jpg');
        .header-hero {
            &-semicircle { fill: rgba(16, 45, 93, .5) }
            &-content { background-color: rgba(16, 45, 93, .5) }
        }
    }
    &-coaching-culture {
        background-image: url('../../Assets/images/Public-Website/hero/coaching-culture-hero.jpg');
        .header-hero {
            &-semicircle { fill: rgba(253, 199, 67, 0.5) }
            &-content { background-color: rgba(253, 199, 67, 0.5) }
        }
        .gelcoat-left {
            background: linear-gradient(to right, rgba(251, 154, 18, 0.679), rgba(0, 0, 0, 0));
        }
    }
    &-speaking-engagements {
        background-image: url('../../Assets/images/Public-Website/hero/speaking-hero.jpg');
        .header-hero {
            &-semicircle { fill: rgba(253, 199, 67, 0.7) }
            &-content { background-color: rgba(253, 199, 67, 0.7) }
        }
        .gelcoat-left {
            background: linear-gradient(to right, rgb(251, 155, 18), rgba(0, 0, 0, 0));
        }
    }
    &-grow-coaching {
        background-image: url('../../Assets/images/Public-Website/hero/grow-coaching-hero.jpg');
        .header-hero {
            &-semicircle { fill: rgba(70, 108, 98, .75) }
            &-content { background-color: rgba(70, 108, 98, .5) }
        }
        .gelcoat-left {
            background: linear-gradient(to right, rgb(51, 151, 140), rgba(0, 0, 0, 0));
        }
    }
    &-executive-coaching {
        background-image: url('../../Assets/images/Public-Website/hero/executive-hero.jpg');
        .header-hero {
            &-semicircle { fill: rgba(70, 108, 98, .5) }
            &-content { background-color: rgba(70, 108, 98, .5) }
        }
        .gelcoat-left {
            background: linear-gradient(to right, rgb(51, 151, 140), rgba(0, 0, 0, 0));
        }
    }
    &-coaching-lab {
        background-image: url('../../Assets/images/Public-Website/hero/coaching-lab-hero.jpg');
        .header-hero {
            &-semicircle { fill: rgba(253, 199, 67, 0.5) }
            &-content { background-color: rgba(253, 199, 67, 0.5) }
        }
        .gelcoat-left {
            background: linear-gradient(to right, rgba(251, 154, 18, 0.679), rgba(0, 0, 0, 0));
        }
    }
    &-coachmasters {
        background-image: url('../../Assets/images/Public-Website/hero/coachmasters-hero.jpg');
        .header-hero {
            &-semicircle { fill: rgba(16, 79, 93, 0.5) }
            &-content { background-color: rgba(16, 45, 93, .5) }
        }
    }
    &-breakthroughs {
        background-image: url('../../Assets/images/Public-Website/hero/breakthroughs-hero.jpg');
        .header-hero {
            &-semicircle { fill: rgba(51, 148, 151, 0.5) }
            &-content { background-color: rgba(51, 148, 151, 0.5) }
        }
    }
}
