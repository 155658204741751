.coordinator-zone {
    margin: 60px 0;
    width: 80vw;
    max-width: 1160px;
    text-align: left;
    h1 {
        margin-top: 0;
    }
}

.file-access-tracking {
    @include flex-center-all;
    margin-top: 50px;
    flex-direction: column;
    form {
        @include flex-column;
        width: 200px;
        margin: 0 0 20px 0;
        border: 1px solid $sprout-dark-blue;
        padding: 20px;
        input {
            padding: 5px;
            margin: 10px 0;
        }
    }
    &-table {
        max-width: 90vw;
        border-collapse: collapse;
        margin: 20px 0;
        tr:nth-child(even) {
            background-color: #eee;
        }
        th, td {
            text-align: left;
            padding: 10px 0;
            padding-right: 10px;
        }
        thead {
            border-bottom: 1px solid black;
        }
    }
}

.coordinator-registration-links {
    margin-top: 50px;
    &-form {
        @include flex-align-center-column;
        padding: 30px 10px;
        margin: 25px;
        &-search {
            flex: 1;
            width: 100%;
            max-width: 800px;
            input {
                width: 100%;
            }
        }
        &-radio {
            flex: 1;
            margin-bottom: 10px;
            label {
                display: flex;
                justify-content: space-between;
                margin: 0 10px 5px;
                width: 170px;
                input {
                    margin-left: 5px;
                }
            }
        }
    }
    &-link {
        @include flex-center-all;
        font-size: 17px;
        word-break: break-all;
        margin: 5px 5px 30px 5px;
        p {
            margin: 0;
            padding: 5px;
            color: $validation-font-red;
            background-color: $validation-background-red;
        }
    }
}
