.rd-map {
    &-states {
        polyline, path {
            stroke: #fff;
            stroke-miterlimit: 1;
            stroke-width: 0.5px;
        }
    }
    &-card {
        text {
            fill: white;
            &:nth-child(2){
                @include montserrat-semi-bold;
                font-size: 14px;
            }
            &:nth-child(3){
                @include montserrat-light;
                font-size: 12px;
            }
        }
        rect {
            fill: $sprout-blue;
            width: 156px;
            height: 41.73px;
        }
        image {
            width: 156px;
            height: 195px;
        }
    }
}
