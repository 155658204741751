.event-details {
    &-content {
        display: flex;
        justify-content: space-between;
        padding: 0 34px;
        width: 100%;
        text-align: left;
        margin-bottom: 150px;
        article {
            width: 100%;
            p, ul, a, ol {
                margin-top: 0;
            }
            li {
                margin-bottom: 5px;
            }
            h4 {
                margin: 30px 0 8px 0;
            }
            a {
                color: $sprout-blue;
                text-decoration: none;
            }
        }
    }
}

.event-registrations {
    &-content {
        padding: 0 34px;
        width: 100%;
        &-header {
            text-align: left;
            margin: 8px 0;
            h2 {
                margin: 0 0 14px;
            }
        }
        &-body {
            display: flex;
            justify-content: space-between;
            height: 100%;
            margin-bottom: 100px;
            article {
                width: 100%;
            }
            &-progress {
                display: flex;
                justify-content: space-between;
                width: 100%;
                position: relative;
                :nth-child(1){
                    background-image: url('../../../Assets/images/Public-Website/design/orange-semi-circle.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-color: $sprout-gold;
                }
                h3 {
                    background-color: $sprout-dark-gray-4;
                    font-weight: 600;
                    padding: 14px 10px;
                    margin: 0 0 8px;
                    width: 30%;
                    text-transform: uppercase;
                    color: white;
                }
            }
            &-steps {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: left;
                text-align: left;
                padding: 26px 43px 20px 19px;
                background-color: $sprout-gray;
                h3 {
                    margin: 0 0 20px;
                }
                section {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    width: 100%;
                }
                ul {
                    position: relative;
                    background-color: white;
                    padding: 2px 30px;
                    margin: 2px 0;
                    li {
                        display: flex;
                        margin: 5px 0;
                        p {
                            margin: 0;
                            width: 8.3vw;
                        }
                        aside {
                            position: absolute;
                            top: 8px;
                            right: 10px;
                            display: flex;
                            flex-direction: column;
                            button {
                                border: none;
                                padding: 5px;
                                background-color: $sprout-blue;
                                color: white;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
                nav {
                    display: flex;
                    justify-content: flex-end;
                    button {
                        min-width: 110px;
                        margin-left: 10px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.event-registrations-confirmation-banner {
    background-color: $sprout-orange;
    color: white;
    display: flex;
    width: 100%;
    padding: 26px 43px 20px 19px;
    margin: -26px 0 30px -19px;

    img {
        width: 14.5vw;
        margin: 0 20px;
    }

    p {
        margin: 10px;
        max-width: 37.31vw;
    }
}

// Stripe
.stripe-wrap {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 40px;
    .stripe-card-element {
        width: 360px;
        padding: 15px 20px;
        background-image: linear-gradient(to top, #193F80, $primary-color, #476AA5);
        label {
            color: white;
            font-family: $whitney-semibold;
            font-size: 16px;
        }
    }
    button {
        margin-left: 20px;
        width: 120px;
        height: 36px;
        border-radius: 5px;
        background: $secondary-color;
        color: white;
        font-size: 10px;
    }
}

.card-input {
    &-wrap {
        background-color: white;
        margin: 10px;
        padding: 10px;
        box-shadow: 0px 16px 40px -15px rgba(0,0,0,0.75);
        
    }
    &-postal {
        border: 0px;
        padding: 0;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        &::placeholder {
            color: rgb(106, 106, 106);
        }
        &:focus{
            outline: none;
        }
    }
}

// Shared between Event Details and Registrations
.registrations-and-event-details-panel {
    width: 34.5vw;
    min-width: 416px;
    margin-left: 38px;
    height: 100%;
    min-height: 100%;
    text-align: left;
    margin-bottom: 100px;

    header {
        background-image: url('../../../Assets/images/Public-Website/design/teal-semi-circle.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-color: $sprout-teal;
        margin-bottom: 30px;
        h3 {
            color: white;
            margin: 0;
            padding: 20px;
        }
    }

    div {
        background-color: $sprout-gray;
        padding-bottom: 20px;
    }

    section {
        padding: 0 21px;
        h4 {
            color: $sprout-blue;
            margin: 5px 0;
        }
        ul {
            list-style: none;
            margin: 0 0 20px 0;
        }
        .event-sold-out {
            color: $sprout-red;
        }
    }

    &-tuition {
        li {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            span:nth-child(2){
                font-style: italic;
                color: gray;
            }
        }
        &-registration {
            h3 {
                margin-top: 32px !important;
            }
            span {
                display: flex;
                justify-content: space-between;
                margin-top: 5px;
                p {
                    margin: 0;
                }
            }
        }
    }

    button {
        margin-top: 20px;
    }
}

.registrations-and-event-details-subheader {
    margin: 32px 10px;
    padding: 16px 18px;
    background-color: $sprout-teal;
    text-align: left;
    color: white;
    background-image: url('../../../Assets/images/Public-Website/design/teal-semi-circle.svg');
    background-repeat: no-repeat;
    background-size: contain;
    h3, p {
        margin: 0;
    }
}

@media (max-width: $mid-breakpoint) {
    .event-details {
        &-content {
            padding: 0 15px;
            width: 100%;
            margin-bottom: 0;
            flex-direction: column;
            article {
                width: 100%;
                margin-bottom: 50px;
            }
        }
    }
    
    .event-registrations {
        &-content {
            padding: 0 15px;
            width: 100%;
            &-body {
                flex-direction: column;
                margin-bottom: 0px;
                &-progress {
                    h3 {
                        padding: 14px 5px;
                    }
                }
                &-steps {
                    margin-bottom: 50px;
                    padding: 26px 15px 20px 15px;
                    width: 100%;
                    ul {
                        li {
                            p {
                                margin: 0;
                                width: 110px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .event-registrations-confirmation-banner {
        background-color: $sprout-orange;
        color: white;
        display: flex;
        width: 100%;
        padding: 26px 43px 20px 19px;
        margin: -26px 0 30px -19px;
        img {
            width: 14.5vw;
            margin: 0 20px;
        }
        p {
            margin: 10px;
            max-width: 37.31vw;
        }
    }
    
    // Shared between Event Details and Registrations
    .registrations-and-event-details-panel {
        width: calc(100% - 30px);
        margin-left: 0;
        margin-bottom: 100px;
    }
}

@media (max-width: $small-breakpoint) {
    .event-details {
        &-content {
            padding: 0;
            width: 100%;
            article {
                width: 100%;
                padding: 0 15px;
            }
        }
    }
    
    .event-registrations {
        &-content {
            padding: 0;
            width: 100%;
            &-body {
                &-progress {
                    flex-direction: column;
                    h3 {
                        padding: 14px 5px;
                        width: 100%;
                    }
                }
                &-steps {
                    ul {
                        li {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 10px;
                            p {
                                width: auto;
                            }
                        }
                    }
                    nav {
                        display: flex;
                        justify-content: center;
                        button {
                            min-width: 110px;
                            margin: 10px;
                        }
                    }
                }
            }
        }
    }
    
    .event-registrations-confirmation-banner {
        background-color: $sprout-orange;
        color: white;
        display: flex;
        width: 100%;
        padding: 26px 43px 20px 19px;
        margin: -26px 0 30px -19px;
        img {
            width: 14.5vw;
            margin: 0 20px;
        }
        p {
            margin: 10px;
            max-width: 37.31vw;
        }
    }
    
    // Shared between Event Details and Registrations
    .registrations-and-event-details-subheader {
        margin: 0 0 25px 0;
    }
}
