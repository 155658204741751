.blog-home {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    width: 100%;
    padding: 30px 5vw 60px 5vw;
    section {
        width: 100%;
        h1 {
            margin: 10px 0;
        }
    }
    &-search {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        input, button {
            margin: 0;
            padding: 5px;
        }

        button {
            background-color: $r-primary-2;
            color: white;
            border: 2px solid $r-primary-2;
            padding: 5px;
        }
    }
    &-pagination {
        display: flex;
        align-items: center;
        span {
            margin: 10px;
        }
        button {
            background-color: white;
            color: $r-primary-1;
            padding: 5px;
            
            cursor: pointer;
            &:disabled {
                color: $r-grayscale-2;
                cursor: default;
            }
        }
        &-text-button {
            width: 60px;
            padding: 5px;
            border: 1px solid $r-primary-1;
            &:disabled {
                border: 1px solid $r-grayscale-2;
            }
        }
        &-icon-button {
            border: none;
        }
    }
}

.blog-card-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
}

.blog-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0px 5% 60px 5%;
    text-align: left;
    div {
        max-width: 100%;
    }
    &-hero {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: $ultra-light-grey;
        margin-bottom: 20px;
        img {
            max-width: 100%;
            max-height: 600px;
        }
    }
    &-body {
        a {
            color: $primary-color;
        }
        a > img {
            display: flex;
        }
        p:has(> img) {
            text-align: center;
        }
        img {
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }            
    }
    h3 {
        margin-bottom: 15px;
    }
    &-categories {
        display: flex;
        flex-wrap: wrap;
        a {
            color: $r-primary-1;
            margin: 10px 5px;;
            padding: 5px 10px;
            border: 1px solid $r-primary-1;
            border-radius: 1em;
            text-decoration: none;
            cursor: pointer;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            transition: all .3s;
            &:hover {
                color: white;
                border: 1px solid white;
                background-color: $r-primary-1;
            }
        }
    }
    &-author {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin-top: 75px;
        background-color: $ultra-light-grey;
        
        
        &-image {
            position: absolute;
            top: -40px;
            left: 0;
            right: 0;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            background-color: white;
            border-radius: 50%;
            box-shadow: 0 2px 15px 0 rgb(0 0 0 / 12%);
            img {
                width: 90px;
                height: 90px;
                border-radius: 50%;
            }
        }
        
        &-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-top: 45px;
            text-align: center;
            &-cite {
                border-top: 2px solid $teal-semi-circle;
                padding-top: 15px;
                text-align: center;
                address {
                    margin-bottom: 5px;
                    a {
                        font-size: 20px;
                        font-weight: 540;
                        color: #192232;
                        font-style: normal;
                        text-decoration: none;
                        &:hover {
                            color: $primary-color;
                        }
                    }
                }
                time {
                    opacity: .75;
                    font-size: 15px;
                    color: #192232;
                    text-transform: uppercase;
                }
            }
        }
    }
}
