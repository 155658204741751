// Sprout Fonts
@mixin montserrat-light {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 300;
}

@mixin montserrat-regular {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 400;
}

@mixin montserrat-medium {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 500;
}

@mixin montserrat-semi-bold {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 600;
}

@mixin montserrat-bold {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
}

@mixin montserrat-extra-bold {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 800;
}

@mixin montserrat-black {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 900;
}

@mixin calibri-light {
    font-family: "Calibri", Helvetica, Arial, sans-serif;
    font-weight: 300;
}

@mixin calibri-regular {
    font-family: "Calibri", Helvetica, Arial, sans-serif;
    font-weight: 400;
}

@mixin calibri-medium {
    font-family: "Calibri", Helvetica, Arial, sans-serif;
    font-weight: 500;
}

@mixin calibri-semi-bold {
    font-family: "Calibri", Helvetica, Arial, sans-serif;
    font-weight: 600;
}

@mixin calibri-bold {
    font-family: "Calibri", Helvetica, Arial, sans-serif;
    font-weight: 700;
}

@mixin calibri-extra-bold {
    font-family: "Calibri", Helvetica, Arial, sans-serif;
    font-weight: 800;
}

@mixin calibri-black {
    font-family: "Calibri", Helvetica, Arial, sans-serif;
    font-weight: 900;
}
