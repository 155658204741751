.portal-container {
    @include flex-align-center-column;
    font-family: $typeface-montserrat;
    color: $sprout-light-blue;
    h2 {
        color: black;
        margin: 1.5em 0 1em 0;
    }
    h3 {
        color: rgba(0, 0, 0, 0.580);
        font-weight: $fontweight-regular;
        margin: 0 0 1.5em;
    }

    p {
        max-width: 28em;
        a {
            color: black;
            text-decoration: none;
        }
    }
    
    form { 
        @include flex-align-center-column;
        width: 100%;
        font-weight: $fontweight-semibold;
        color: $sprout-dark-gray;
        outline: none;
        gap: 0.625em;

        label {
            @include flex-align-start-column;
            gap: 0.625em;
            color: black;
            
            .password-visibility {
                padding: 10px;
                min-width: 40px;
                position: absolute;
                margin-left: 250px;
                margin-top: 30px;
            }
            input {
                width: 18.75em;
                padding: 0.7em;
                margin-bottom: 0.625em;
                outline: 0.125em solid $sprout-gray-2;
                border: none;
                border-radius: 0.313em;
                background-color: $sprout-gray;
                &-wrap {
                    @include flex-align-start-column;
                }
                &:focus {
                    outline: none;
                }
                &::placeholder {
                    font-weight: $fontweight-regular;
                    opacity: .5;
                }
                p {
                    font-size: 0.688em;
                    margin: 0em;
                    max-width: 28em;
                    a {
                        color: black;
                        text-decoration: none;
                    }
                }
            }
        }   

        .portal-buttons-container {
            @include flex-between-center-column;
            width: 18.75em;
            margin: 1.3em 0em;
            button {
                width: 18.75em;
                padding: 0.7rem;
                border: 0.125em solid $sprout-blue;
                background: $sprout-blue;
                color: white;
                cursor: pointer;
                transition: ease-in-out 0.2s;
                outline: none;
                border-radius: 0.313em;
                letter-spacing:0.1rem;
                &:hover {
                    background: $sprout-light-blue;
                    border: 0.125em solid $sprout-light-blue;
                }
                &:active {
                    border: solid 1px white;
                }
            }
            a {
                color: black;
                position: relative;
                right: 86px;
                padding: 1em 0.25em;
                font-weight: $fontweight-regular;
                &:hover {
                    color: $sprout-light-blue;
                }
            }
            p {
                color: $sprout-dark-red;
                font-weight: $fontweight-regular;
                margin-bottom: 1em;
            }
        }

        .terms-and-conditions {
            &-wrap {
                display: flex;
                align-items: baseline;
                justify-content: center;
                width: 18.75em;
                input {
                    width: 1em;
                }
            }
            &-agreement {
                max-width: 18.75em;
                text-align: center;
            }
            &-toggle {
                color: $sprout-blue;
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.portal-link-container {
    @include flex-align-center-column;
    color: black;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    outline: none;
    font-weight: $fontweight-regular;
    margin: 0em 0em 1.25em 0em;
    padding-bottom: 0.625em;
    gap: 0.625em;
    a {
        color: black;
        text-decoration: none;
        &:hover {
            color: $sprout-light-blue;
        }
        strong {
            color: $sprout-blue;
            font-weight: $fontweight-semibold;
        }
    }
}
