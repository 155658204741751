.speech-bubble {
    filter: drop-shadow(-1px -1px 2px rgba(black, .10)) drop-shadow(1px 2px 2px rgba(black, .15));
    margin: 1rem;
    margin-bottom: 80px;
    padding: 1.5rem 2rem;
    position: relative;
    font-size: 1.2rem;
    font-family: $typeface-montserrat;
    font-weight: $fontweight-regular;
    background: $sprout-light-blue;
    color: white;
    text-align: left;
    border: 1px solid $sprout-dark-gray-4;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 8px 10px;
    &-values {
        @include flex-wrap;
        justify-content: center;
        padding: 10px 20px;
        img {
            margin: 5px;
            display: block;
            max-width: 84px;
            max-height: 48px;
            width: auto;
            height: auto;
        }

        &-block {
            text-align: center;
            height: 100px;
            p {
                display: inline-block;
                margin: 0;
                font-size: 1.2rem;
                font-weight: 400;
                color: white;
            }
            .like-button {
                cursor: pointer;
                margin-right: 10px;
                &:hover {
                    color: goldenrod;
                }
            }
            .liked-button {
                cursor: pointer;
                margin-right: 10px;
                color: $validation-font-green;
            }
        }

    }

    header {
        @include flex-wrap;
        h4 {
            margin: 0 6px 10px 0;
        }
    }

    &::before {
        content:"\A";
        border-style: solid;
        border-width: 12px 12px;
        position: absolute;
        right: auto;
        bottom: -24px;
        border-color: $sprout-blue $sprout-blue transparent transparent;
    }

    &-comments {
        cursor: pointer;
        margin-right: 15px;
        &:hover {
            color: goldenrod;
        }
    }
    &-addComment {
        cursor: pointer;
        &:hover {
            color: goldenrod;
        }
    }
}

.speech-bubble cite {
    position: absolute;
    text-align: left;
    bottom: -2.75rem;
    left: 4.5rem;
    font-size: 1rem;
    font-family: $typeface-montserrat;
    font-weight: $fontweight-semibold;
    letter-spacing: 0.5px;
    color: $sprout-blue;
    width: 200px;
}

.high-five-comment {
    @include flex-column;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid black;
    background: $light-blue;
    font-family: $typeface-calibri;
    font-size: 15px;
    font-weight: 400;
    color: $sprout-font-gray;
    text-align: left;
    p {
        margin: 0;
        margin-top: 20px;
        font-size: 15px;
    }
}

.highfives-carousel {
    @include flex-center-all;
    width: 100%;
    height: 60vh;
    overflow: auto
}

.highfives-carousel-wrap {
    flex-direction: column;
    justify-content: center;
    @include flex-center-all;
    position: fixed;
    background-color: white;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.highfives-carousel__slides {
    margin: 0px;
    padding: 0px;
    width: 500px;
}

.highfives-carousel__slide {
    margin-right: auto;
    margin-left: auto;
    display: none;
    list-style-type: none;
    text-align: center;
    &--active {
        display: block;
    }
}

.highfives-carousel-button-toggle {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $sprout-blue;
    color: white;
    width: 60px;
    height: 35px;
    border: 1px solid $sprout-blue;
    border-radius: 10px;
    font-size: 14px;
    font-family: $typeface-montserrat;
    font-weight: 600;
    margin: 5px;

    &:focus {
        outline: none;
    }
    &:hover {
        cursor: pointer;
    }
}

@media (max-width: 767px) {
    .highfives-carousel {
        height: auto;
    } 

    .highfives-carousel__slides {
        width: 90%;
    }
}
