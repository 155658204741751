
.solutions-landing-video {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 34px 10px;
    @include breakpoint('lg') {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: baseline;
        padding: 4.24vw 34px 4.24vw 34px;
    }
    &-thumbnail {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        max-height: 55.97vw;
        @include breakpoint('lg') {
            justify-content: flex-end;
            width: 50vw;
            max-width: 50vw;
            max-height: 28.846153846153847vw;
        }
    }
    section, article {
        text-align: left;
        margin-left: 0;
        width: 100%;
        max-width: 640px;
        @include breakpoint('lg'){
            text-align: left;
            margin-left: 2vw;
            max-width: 50%;
        }
        p {
            max-width: unset;
            margin-top: 16px;
            @include breakpoint('lg') {
                margin-top: 0px;
                max-width: 70%;
            }
        }
    }
}

.solutions-landing-table {
    border-collapse: collapse;
    text-align: left;
    color: $sprout-font-gray;
    &-header {
        h2 {
            margin: 20px 0 0 10px;
            @include breakpoint('lg') {
                margin: 3vw 0 0 3vw;
            }
        }
    }
    &-info {
        margin: 10px 0;
        width: calc(100% - 20px);
        flex-direction: column;
        max-height: unset;
        height: auto;
        @include breakpoint('lg') {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin: 2% 0;
            height: 21.5vw;
            max-height: 300px;
        }
        &-icon {
            height: 50px;
            width: 50px;
            max-height: unset;
            align-self: center;
            @include breakpoint('lg') {
                height: 10vw;
                width: 10vw;
                max-height: 125px;
                max-width: 125px;
            }
        }
        td {
            flex-direction: column;
            display: flex;
            align-items: flex-start;
            padding: 10px;
            width: 100%;
            @include breakpoint('lg') {
                display: block;
                width: 25%;
                padding: 2% 1% 2% 3%;
            }
            &:nth-child(odd){
                background-color: $sprout-gray;
            }
            &:nth-child(even){
                background-color: $sprout-gray-2;
            }
            h3 {
                align-self: center;
                margin: 10px 0 0 0;
                @include breakpoint('lg') {
                    align-self: baseline;
                }
            }
            p, ul {
                margin: 5px 0;
                @include breakpoint('lg') {
                    margin-top: 15px;
                }
            }
            ul {
                padding: 0 0 0 15px;
            }
            strong {
                img {
                    margin-right: 10px;
                    margin-left: -10px;
                    height: 18px;
                    @include breakpoint('lg') {
                        margin-left: -25px;
                        margin-top: 4px;
                        height: 1.66vw;
                        max-height: 23px;
                        width: auto;
                    }
                }
            }
            a {
                align-self: center;
                text-decoration: none;
                color: $sprout-blue;
                margin-top: 10px;
                @include breakpoint('lg') {
                    align-self: baseline;
                }
                svg { 
                    margin-left: 10px;
                }
                &:hover {
                    color: $sprout-orange;
                }
            }
        }
    }
}
