.clients {
    &-logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 32px;
        }
        img {
            width: 15vw;
            max-width: 210px;
            margin: 20px;
            filter: grayscale(1);
        }
    }

    &-stories {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 67px 35px;
        text-align: left;
        ul {
            display: flex;
            justify-content: space-evenly;
            list-style: none;
            padding: 0;
        }
        &-header {
            h2 {
                margin: 0 0 50px 0;
            }
            &-filter {
                display: flex;
                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 14.1vw;
                    height: 2.41vw;
                    max-width: 197px;
                    max-height: 34px;
                    margin-right: 21px;
                    color: white;
                    background-color: $sprout-dark-gray;
                }
                .filter-active {
                    background-color: $sprout-teal;
                }
            }
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            margin: 50px 0 0 0;
            & > * {
                justify-content: center;
            }
        }
    }
}

@media (max-width: $mid-breakpoint) {
    .clients {
        &-stories {
            padding: 45px 15px;
            width: 100%;
            &-header {
                ul {
                    justify-content: flex-start;
                }
                width: 100%;
                h2 {
                    margin: 0;
                }
                &-filter {
                    flex-wrap: wrap;
                    &-item {
                        width: 115px;
                        height: 30px;
                        margin: 0 22px 10px 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $small-breakpoint) {
    .clients {
        &-logos {
            &-wrapper {
                padding: 15px;
            }
            img {
                max-width: 100px;
                margin: 10px;
                filter: grayscale(1);
            }
        }
    }
}
