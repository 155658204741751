// Old Redesign Mixins
@mixin teal-curve-background {
    background-image: url("../../Assets/images/Website/Teal-Round-Top.svg");
    background-color: white; 
    background-position: center top; 
    background-repeat: no-repeat; 
    background-size: cover;
}

@mixin background-image-center-right {
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin background-image-center {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin bottom-curve-background-white {
    background-image: url("../../Assets/images/Website/round-bottom-white.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin bottom-curve-background-gray {
    background-image: url("../../Assets/images/Website/round-bottom-gray.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
}

@mixin bottom-curve-background-dynamic {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
}

@keyframes rotate-keyframes {
    from {
        transform: rotate(-90deg);
    }
    to {
        transform: rotate(0deg);
    }
}

//Global Mixins
@mixin flex-content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-self-center {
    display: flex;
    justify-self: center;
    align-self: center;
}

@mixin flex-space-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@mixin flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-align-center-column {
    display: flex;
    align-items: center;
    flex-direction: column;
}


//IOD Site Mixins

@mixin maxWidth {
    width: 100%;
    max-width: 845px;
}


@mixin button-transparent-to-white {
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
    height: 36px;
    line-height: 34px;
    font-size: 16px;
    text-align: center;
    padding: 0 15px;
    font-family: $whitney-medium;
    transition: all .3s;
    -webkit-transition: all .3s;

    a {
        text-decoration: none;
        color: #fff;
    }

    &:hover {
        color: $primary-color;
        background-color: #fff;

        a {
            color: $primary-color;
        }
    }
}

@mixin button-white-to-blue {
    color: $primary-color;
    border: 1px solid $primary-color;
    background-color: #fff;
    height: 36px;
    line-height: 34px;
    font-size: 16px;
    text-align: center;
    padding: 0 15px;
    font-family: $whitney-medium;
    transition: all .3s;
    -webkit-transition: all .3s;

    a {
        text-decoration: none;
        color: $primary-color;
    }

    &:hover {
        color: #fff;
        background-color: $primary-color;

        a {
            color: #fff;
        }
    }
}

//Analytics mixins
@mixin analytics-card-styles {
    grid-row: 10/14;
    background-color: white;
    color: gray;
    box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 20px;
    transition: 0.3s ease-in-out;
    text-decoration: none;
    svg {
        grid-row: 1/3;
    }
    header {
        font-size: 1.7vw;
        grid-row: 3/4;
    }
    p {
        margin: 0;
        font-size: 1vw;
        font-family: Arial, Helvetica, sans-serif;
        grid-row: 4/5;
    }
    &:hover {
        box-shadow: -10px 10px 14px rgba(0, 0, 0, .6);
        cursor: pointer;
    }
}
