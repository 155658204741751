.continuing-education {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 100%;
    section {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: left;
        padding: 45px;
        min-height: 40px;
        header {
            p {
                font-size: 22px;
                font-family: $whitney-book;
            }
        }
        img {
            align-self: flex-start;
            width: 135px;
        }
        h1, h2 {
            color: $primary-color;
            font-family: $whitney-semibold;
        }
        h1 {
            font-size: 42px;
            margin: 0 0 18px 0;
        }
        h2 {
            font-size: 38px;
            margin: 0 0 26px 0;
        }
        p {
            color: $grey-font;
            margin:  0 0 12.5px 0;
            line-height: 25.7143px;
            a {
                text-decoration: none;
                color: $primary-color;
                font-family: $whitney-semibold;
                &:hover {
                    font-family: $whitney-bold;
                }
            }
        }
        &.grey-background {
            background-color: #f0f0f0;
        }
    }

    &-list {
        padding: 0 45px;
        p {
            font-size: 24px;
            font-family: $whitney-semibold;
        }
        ul {
            li {
                font-size: 22px;
                font-family: $whitney-book;
                margin-bottom: 15px;
            }
        }
        aside {
            font-size: 18px;
            font-family: $whitney-book;
        }
    }
}

@media (max-width: 1180px) {
    .continuing-education {
        section {
            align-items: center;
            width: 80vw;
        }
    }
}

@media (max-width: 650px) {
    .continuing-education {
        section {
            width: 90vw;
            display: flex;
            flex-direction: column;
            padding: 20px 100vw;
            img {
                width: 100px;
                margin-bottom: 10px;
            }
            h1 {
                font-size: 26px;
                margin: 0 0 18px 0;
            }
            h2 {
                font-size: 24px;
                margin: 0 0 26px 0;
            }
        }
        &-list {
            padding: 0;
            p {
                font-size: 22px;
                font-family: $whitney-semibold;
            }
            ul {
                li {
                    font-size: 20px;
                    font-family: $whitney-book;
                    margin-bottom: 15px;
                }
            }
            aside {
                font-size: 16px;
                font-family: $whitney-book;
            }
        }
    }
}