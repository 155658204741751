.testimonials {
    background-color: $sprout-gold;
    h2 {
        margin: 0;
        padding: 45px 0 30px 0;
        color: $sprout-font-gray;
    }
    &-container {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 15px;
        margin: 0;
        &-card {
            width: 21.72vw;
            max-width: 33%;
            // min-height: 27.28vw;
            margin: 15px;
            background-color: white;
            img {
                width: 100%;
            }
            &-content {
                padding: 10px 16px;
                text-align: left;
                h3 {
                    margin: 0;
                }
                h4 {
                    margin: 0 0 10px 0;
                    font-style: italic;
                }
                q, p {
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: $mid-breakpoint) {
    .testimonials {
        h2 {
            padding: 25px 0 10px 0;
        }
        &-container {
            flex-wrap: wrap;
            &-card {
                width: 300px;
                min-height: 350px;
                max-width: unset;
            }
        }
    }
}
