.main-header {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: 100vw;
    height: 62px;
    z-index: 3;
    top: 0;

    &-body {
        @include flex-space-between;
        width: 1260px;
        height: 36px;
        padding: 0 5px;
        background: white;
        z-index: 3;
    }

    &-contact {
        height: 36px;
        margin-right: 50px;
        line-height: 36px;
    }

    &-portal {
        height: 25px;
        a {
            color: white;
            text-decoration: none;;
        }
        
    }

    button {
        font-size: 14px;
        font-family: $whitney-medium;
        background-color: #f5c945;
        color: #fff;
        padding: 0 16px;
        border: 0px;
        margin-top: 5px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        &:hover {
            border: solid 1px $bright-yellow-alt;
            background: $bright-yellow-alt;
            cursor: pointer;
        }
    }
}

.header-username {
    display: flex;
    align-items: center;
    font-family: $whitney-semibold;
    &-name {
        text-decoration: none;
        color: $dark-grey;
        font-size: 17px;
    }
    button {
        margin-left: 20px;
    }
}

.side-panel-hamburger {
    margin-right: 15px;
    font-size: 24px;
}

.profile-menu-wrapper {
    position: fixed;
    top: 15px;
    right: 50px;
}

.profile-menu-toggle{
    @include flex-content-center;
    color: $primary-color;
    border-radius: 10px;
    padding: 5px;
    background-color: white;
    &:hover {
        background-color: $very-light-grey;
        cursor: pointer;
    }
    &:active {
        background-color: rgb(204, 204, 204);
    }
}
.profile-menu-dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    margin-top: 2px;
    background-color: white;
    border: 2px solid $grey;
    border-radius: 8px;
    text-decoration: none;
    z-index: 123456;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
    font-family: $font;
    font-weight: 500;
    &-item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 30px;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
            background-color: $very-light-grey;
        }
        &:active {
            background-color: rgb(204, 204, 204);
        }
    }
}
.profile-menu-name {
    margin: 0px 5px;
}
#simple-menu {
    z-index: 123456;
}
.styled-menu {
    margin-top: 35px;
    margin-left: -30px;
}
.header-logo {
    width: 257px;
    margin: 0 0 10px 75px;
}
.account-btn {
    background: white;
}
.account-icon {
    color: $primary-color;
}

@media (max-width: 1100px) {
    .header-logo {
        margin: 0 0 0 20px;
    }
}

@media (max-width: 650px) {
    .header-logo {
        width: 240px;
        height: 38px;
        margin: 0px;
    }
    .profile-menu-wrapper {
        margin: 0px;
    }
    .profile-menu-name {
        display: none;
    }
    .profile-menu-wrapper {
        right: 10px;
        width: 60px;
    }
    .profile-menu-dropdown {
        width: 200px;
        position: fixed;
        top: 48px;
        right: 1px;
    }
}

@media (max-width: 330px) {
    .header-logo {
        width: 220px;
    }
}