.our-office {
    @include padding-section('with-header');
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $sprout-gray;
    h2 {
        margin: 0 0 10px 0;
        width: 100%;
    }
    img {
        margin-bottom: 5px
    }
    address {
        width: 350px;
        text-align: left;
        font-style: normal;
        span {
            display: flex;
            flex-direction: column;
            margin-top: 15px
        }
        p, a {
            margin: 0 0 5px 0;
            text-decoration: none;
            color: black;
        }
    }
    @include breakpoint('lg'){
        // background-color: red;
        address {
            padding-left: 50px;
            span {
                margin: 0 0 15px;
            }
        }
    }
}

.key-outcomes {
    background-color: $sprout-gray;
    padding-top: 4.24vw;
    header {
        h2 {
            color: $sprout-blue;
            margin: 0;
            padding-bottom: 4.24vw;
        }
    }
    ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            padding: 4vw 2.5vw 5vw 2.5vw;
            &:nth-child(odd){
                background-color: $sprout-gray-2;
            }
            img {
                width: 100%;
                height: 6vw;
                max-height: 84px;
            }
            strong {
                margin-top: 20px;
            }
            p {
                margin-bottom: 0;
            }
        }
    }
}


@media (max-width: $mid-breakpoint) {
    // .our-office {
    //     padding: 20px 10px;
    //     h2 {
    //         text-align: center;
    //         margin: 0 !important;
    //         width: 100%;
    //     }
    //     div {
    //         align-items: center;
    //         flex-direction: column;
    //         img {
    //             margin-right: 0;
    //             margin-bottom: 20px;
    //         }
    //         address {
    //             display: flex;
    //             justify-content: space-evenly;
    //             flex-wrap: wrap;
    //             text-align: left;
    //             margin-left: 0px;
    //             span {
    //                 width: 250px;
    //                 margin: 0 10px 20px 10px;
    //             }
    //         }
    //     }
    // }
    .key-outcomes {
        padding-top: 20px;
        h2 {
            padding-bottom: 20px;
        }
        ul {
            flex-direction: column;
            li {
                padding: 30px 10px;
                img {
                    height: 75px;
                }
            }
        }
    }
}
