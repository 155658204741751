.public-events {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 34px 70px 34px;
    width: calc(100% - 68px);
    &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    header {
        width: 100%;
        text-align: left;
        h2 {
            color: $sprout-font-gray;
            text-align: left;
            margin: 50px 0 25px 0;
        }
        h3 {
            margin: 0 0 1vw 0;
            color: $sprout-font-gray;
        }
        p {
            margin: 0;
            color: $sprout-font-gray;
        }
    }
    &-toggle-icon {
        width: 100%;
        color: $sprout-font-gray;
        display: flex;
        justify-content: flex-end;
    }
    &-table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
        margin-top: 25px;
        &-thead {
            th {
                padding: 15px 0px 15px 25px;
            }
        }
        &-tbody {
            &-event {
                &-th {
                    color: white;
                    background-color: $sprout-blue;
                    padding: 5px 0 5px 25px;
                }
                td {
                    color: black;
                    text-decoration: none;
                    padding: 10px 0 10px 25px;
                }
                &:nth-child(even){
                    background-color: $sprout-gray;
                }
            }
            .table-see-more {
                text-align: right;
                background-color: white;
                border-top: 1px solid $sprout-dark-gray;
                button {
                    border: none;
                    color: white;
                    background-color: $sprout-salmon;
                    margin-left: 10px;
                    font-size: 18px;
                }
            }
        }
    }
    &-webinars {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 20px 0;
        &-item {
            display: flex;
            flex-direction: column;
            height: 315px;
            list-style: none;
            padding: 0;
            margin: 10px 10px 50px 10px;
            text-align: left;
            li {
                margin: 0;
            }
            img {
                width: 240px;
                max-width: 240px;
                height: 140px;
                max-height: 140px;
            }
            .title {
                width: 240px;
                font-size: 16px;
                font-weight: bold;
                margin: 25px 0 10px 0;
                height: auto;
                a {
                    color: #46474A;
                    text-decoration: none;
                }
            }
            .date {
                font-size: 15px;
                p, time {
                    height: auto;
                    margin: 0;
                }
            }
            button {
                justify-self: flex-end;
            }
        }
    }
    &-see-more {
        width: 100%;
        font-size: 18px;
        line-height: 40px;
        text-align: right;
        background-color: white;
        border-top: 1px solid $sprout-dark-gray;
        padding: 10px 0 10px 25px;
        button {
            border: none;
            color: white;
            background-color: $sprout-salmon;
            margin-left: 10px;
            font-size: 18px;
        }
    }
}

@media (max-width: $mid-breakpoint) {
    .public-events {
        margin: 0 15px 70px 15px;
        width: calc(100% - 30px);
        header {
            h2 {
                margin: 30px 0 15px 0;
            }
        }
        &-table {
            &-thead {
                th {
                    padding: 15px 0px 15px 15px;
                }
            }
            &-tbody {
                &-event {
                    &-th {
                        padding: 2px 0 2px 15px;
                    }
                    td {
                        padding: 10px 0 10px 15px;
                    }
                }
            }
        }
    }
}

@media (max-width: $small-breakpoint) {
    .public-events {
        margin: 0 15px 70px 15px;
        width: calc(100% - 30px);
        header {
            h2 {
                margin: 30px 0 15px 0;
            }
            p {
                margin-top: 8px;
            }
        }
        &-coaching-table {
            tbody {
                td {
                    // add extra space below last element and above first
                    &:nth-child(4){
                        padding-bottom: 20px;
                    }
                    &:nth-child(1){
                        padding-top: 20px;
                    }
                }
            }
        }
        &-breakthroughs-table {
            tbody {
                td {
                // add extra space below last element and above first
                    &:nth-child(3){
                        padding-bottom: 20px;
                    }
                    &:nth-child(1){
                        padding-top: 20px;
                    }
                }
            }
        }
        &-table {
            display: block;
            &-thead {
                display: none;
            }
            &-tbody {
                display: block;
                width: 100%;
                &-event {
                    display: flex;
                    flex-direction: column;
                    td {
                        padding: 2px 15px;
                        a {
                            color: $sprout-blue;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
